.Icon {
  position: relative;
  display: inline-block;
  width: var(--margin-5);
  height: var(--margin-5);
  padding: var(--margin-1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*
	-webkit-filter: drop-shadow(1px 1px 2px var(--shadow-color));
	-moz-filter: drop-shadow(1px 1px 2px var(--shadow-color));
	-ms-filter: drop-shadow(1px 1px 2px var(--shadow-color));
	-o-filter: drop-shadow(1px 1px 2px var(--shadow-color));*/
}

.Icon.fillparent {
  width: 100%;
  height: 100%;
}

.Icon > div {
  width: 100%;
  height: 100%;
  mask-image: url(./assets/menu-1.svg);
  background-color: gray;

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.Icon._360 > div {
  mask-image: url(./assets/360-angle.svg);
}

.Icon.ar > div {
  mask-image: url(./assets/ar.svg);
}

.Icon.back > div {
  mask-image: url(./assets/back.svg);
}

.Icon.home > div {
  mask-image: url(./assets/icon-home.svg);
}

.Icon.calendar > div {
  mask-image: url(./assets/calendar.svg);
}

.Icon.chat > div {
  mask-image: url(./assets/chat.svg);
}

.Icon.close_2 > div {
  /*mask-image: url(./assets/close_2.svg);*/
  mask-image: url(./assets/close.svg);
}

.Icon.download > div {
  mask-image: url(./assets/file-download.svg);
}

.Icon.hand > div {
  mask-image: url(./assets/hand.svg);
}

.Icon.hotspot > div {
  mask-image: url(./assets/hotspot.svg);
}

.Icon.info > div {
  mask-image: url(./assets/info.svg);
}

.Icon.logout > div {
  mask-image: url(./assets/logout.svg);
}

.Icon.mail > div {
  mask-image: url(./assets/mail.svg);
}

.Icon.next-page > div {
  mask-image: url(./assets/next-page.svg);
}

.Icon.palette > div {
  mask-image: url(./assets/palette.svg);
}

.Icon.play > div {
  mask-image: url(./assets/play.svg);
}

.Icon.refresh > div {
  mask-image: url(./assets/refresh.svg);
}

.Icon.right > div {
  mask-image: url(./assets/right.svg);
}

.Icon.search > div {
  mask-image: url(./assets/search.svg);
}

.Icon.settings > div {
  mask-image: url(./assets/settings.svg);
}

.Icon.user > div {
  mask-image: url(./assets/user.svg);
}

.Icon.video > div {
  mask-image: url(./assets/video.svg);
}

.Icon._3d > div {
  mask-image: url(./assets/icon-3d.svg);
}

.Icon.activity > div {
  mask-image: url(./assets/icon-activity.svg);
}

.Icon.audio-off > div {
  mask-image: url(./assets/icon-audio-off.svg);
}

.Icon.audio-on > div {
  mask-image: url(./assets/icon-audio-on.svg);
}

.Icon.agenda > div {
  mask-image: url(./assets/icon-agenda.svg);
}

.Icon.archive > div {
  mask-image: url(./assets/icon-archive.svg);
}

.Icon.calendar_add > div {
  mask-image: url(./assets/icon-calendar-add.svg);
}

.Icon.calendar > div {
  mask-image: url(./assets/icon-calendar.svg);
}

.Icon.call_waiting > div {
  background-image: url(./assets/icon-call-loading.gif);
}

.Icon.call_accept > div {
  background-image: url(./assets/icon-call-accept.svg);
}

.Icon.call_end > div {
  background-image: url(./assets/icon-call-end.svg);
}

.Icon.call > div {
  mask-image: url(./assets/icon-call.svg);
}

.Icon.cam > div {
  mask-image: url(./assets/icon-cam.svg);
}

.Icon.chat > div {
  mask-image: url(./assets/icon-chat.svg);
}

.Icon.chevron_right > div {
  mask-image: url(./assets/icon-chevron-right.svg);
}

.Icon.chevron_up > div {
  mask-image: url(./assets/icon-chevron-up.svg);
}

.Icon.chevron_down > div {
  mask-image: url(./assets/icon-chevron-down.svg);
}

.Icon.clock > div {
  mask-image: url(./assets/icon-clock.svg);
}

.Icon.close > div {
  mask-image: url(./assets/icon-close.svg);
}

.Icon.company > div {
  mask-image: url(./assets/icon-company.svg);
}

.Icon.contact_request > div {
  mask-image: url(./assets/icon-contact-request.svg);
}

.Icon.contact_suggestions > div {
  mask-image: url(./assets/icon-contact-suggestions.svg);
}

.Icon.contactinfo > div {
  mask-image: url(./assets/icon-contactinfo.svg);
}

.Icon.contacts_saved > div {
  mask-image: url(./assets/icon-contacts-saved.svg);
}

.Icon.contacts > div {
  mask-image: url(./assets/icon-contacts.svg);
}

.Icon.document > div {
  mask-image: url(./assets/icon-document.svg);
}

.Icon.down > div {
  mask-image: url(./assets/icon-down.svg);
}

.Icon.download > div {
  mask-image: url(./assets/icon-download.svg);
}
.Icon.download_ics > div {
  mask-image: url(./assets/icon-ics.png);
}

.Icon.enter > div {
  mask-image: url(./assets/icon-enter.svg);
}

.Icon.file-type-unknown > div {
  mask-image: url(./assets/icon-filetype-unknown.svg);
}

.Icon.file-type-image > div {
  mask-image: url(./assets/icon-filetype-img.svg);
}

.Icon.file-type-video > div {
  mask-image: url(./assets/icon-filetype-video.svg);
}

.Icon.file-type-text > div {
  mask-image: url(./assets/icon-filetype-text.svg);
}

.Icon.file-type-pdf > div {
  mask-image: url(./assets/icon-filetype-pdf.svg);
}

.Icon.file-type-object_view > div {
  mask-image: url(./assets/icon-filetype-object_view.svg);
}

.Icon.html > div {
  mask-image: url(./assets/icon-html.svg);
}

.Icon.iframe > div {
  mask-image: url(./assets/icon-iframe.svg);
}

.Icon.image > div {
  mask-image: url(./assets/icon-image.svg);
}

.Icon.left > div {
  mask-image: url(./assets/icon-left.svg);
}

.Icon.like > div {
  mask-image: url(./assets/icon-like.svg);
}
.Icon.link > div {
  mask-image: url(./assets/icon-link.svg);
}

.Icon.meeting-type-call > div {
  mask-image: url(./assets/icon-meeting-type-call.svg);
}

.Icon.meeting-type-broadcast > div {
  mask-image: url(./assets/icon-meeting-type-broadcast.svg);
}

.Icon.meeting-type-conference > div {
  mask-image: url(./assets/icon-meeting-type-conference.svg);
}

.Icon.menu > div {
  mask-image: url(./assets/icon-menu.svg);
}

.Icon.messenger_user > div {
  mask-image: url(./assets/icon-messenger-user.svg);
}

.Icon.mic > div {
  mask-image: url(./assets/icon-mic.svg);
}

.Icon.network > div {
  mask-image: url(./assets/icon-network.svg);
}

.Icon.overview > div {
  mask-image: url(./assets/icon-overview.svg);
}

.Icon.plus > div {
  mask-image: url(./assets/icon-plus.svg);
}

.Icon.preview > div {
  mask-image: url(./assets/icon-preview.svg);
}

.Icon.profile > div {
  mask-image: url(./assets/icon-profile.svg);
}

.Icon.raisehand > div {
  mask-image: url(./assets/icon-raisehand.svg);
}

.Icon.save > div {
  mask-image: url(./assets/icon-save.svg);
}

.Icon.schedule > div {
  mask-image: url(./assets/icon-schedule.svg);
}

.Icon.search > div {
  mask-image: url(./assets/icon-search.svg);
}

.Icon.sharescreen > div {
  mask-image: url(./assets/icon-sharescreen.svg);
}

.Icon.skills > div {
  mask-image: url(./assets/icon-skills.svg);
}

.Icon.sort_list > div {
  mask-image: url(./assets/icon-sort-list.svg);
}

.Icon.sort_tiles > div {
  mask-image: url(./assets/icon-sort-tiles.svg);
}

.Icon.stage > div {
  mask-image: url(./assets/icon-stage.svg);
}

.Icon.time > div {
  mask-image: url(./assets/icon-time.svg);
}

.Icon.trash > div {
  mask-image: url(./assets/icon-trash.svg);
}

.Icon.user > div {
  mask-image: url(./assets/icon-user.svg);
}

.Icon.video > div {
  mask-image: url(./assets/icon-video.svg);
}

.Icon.video-off > div {
  mask-image: url(./assets/icon-video-off.svg);
}

.Icon.video-on > div {
  mask-image: url(./assets/icon-video-on.svg);
}

.Icon.videocall > div {
  mask-image: url(./assets/icon-videocall.svg);
}

.Icon.workshop > div {
  mask-image: url(./assets/icon-workshop.svg);
}

.Icon.write > div {
  mask-image: url(./assets/icon-write.svg);
}

.Icon.zoom-in > div {
  mask-image: url(./assets/icon-zoom-in.svg);
}

.Icon.zoom-out > div {
  mask-image: url(./assets/icon-zoom-out.svg);
}

.Icon.custom > div {
  mask-image: none;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Icon.remote_sharing > div {
  mask-image: url(./assets/remote-share.png);
}

.Icon.remote_listening > div {
  mask-image: url(./assets/remote-listen.png);
}

.Icon.threejs_playOnClick > div {
   mask-image: url(./assets/threejsui/icon-playOnClick.svg);
}

.Icon.threejs_uiShow > div {
   mask-image: url(./assets/threejsui/icon-uiShow.svg);
}

.Icon.threejs_uiHide > div {
   mask-image: url(./assets/threejsui/icon-uiHide.svg);
}

.Icon.threejs_3ddetail > div {
   mask-image: url(./assets/threejsui/icon-3ddetail.svg);
}

.Icon.threejs_3ddetail_no_space > div {
   mask-image: url(./assets/threejsui/icon-3ddetail-noSpace.svg);
}

.Icon.threejs_animation > div {
   mask-image: url(./assets/threejsui/icon-animation.svg);
}

.Icon.threejs_animation_no_space > div {
  mask-image: url(./assets/threejsui/icon-animation-noSpace.svg);
}

.Icon.threejs_close > div {
   mask-image: url(./assets/threejsui/icon-close.svg);
}

.Icon.threejs_close_no_space > div {
   mask-image: url(./assets/threejsui/icon-close-noSpace.svg);
}

.Icon.threejs_comments > div {
   mask-image: url(./assets/threejsui/icon-comments.svg);
}

.Icon.threejs_comments_no_space > div {
   mask-image: url(./assets/threejsui/icon-comments-noSpace.svg);
}

.Icon.threejs_configuration > div {
  mask-image: url(./assets/threejsui/icon-configuration.svg);
}

.Icon.threejs_configuration_no_space > div {
  mask-image: url(./assets/threejsui/icon-configuration-noSpace.svg);
}

.Icon.threejs_info > div {
  mask-image: url(./assets/threejsui/icon-info.svg);
}

.Icon.threejs_info_no_space > div {
  mask-image: url(./assets/threejsui/icon-info-noSpace.svg);
}

.Icon.threejs_links > div {
  mask-image: url(./assets/threejsui/icon-links.svg);
}

.Icon.threejs_links_no_space > div {
  mask-image: url(./assets/threejsui/icon-links-noSpace.svg);
}

.Icon.threejs_material > div {
  mask-image: url(./assets/threejsui/icon-material.svg);
}

.Icon.threejs_material_no_space > div {
  mask-image: url(./assets/threejsui/icon-material-noSpace.svg);
}

.Icon.threejs_media > div {
  mask-image: url(./assets/threejsui/icon-media.svg);
}

.Icon.threejs_media_no_space > div {
  mask-image: url(./assets/threejsui/icon-media-noSpace.svg);
}

.Icon.threejs_perspectives > div {
  mask-image: url(./assets/threejsui/icon-perspectives.svg);
}

.Icon.threejs_perspectives_no_space > div {
  mask-image: url(./assets/threejsui/icon-perspectives-noSpace.svg);
}

.Icon.threejs_tint > div {
  mask-image: url(./assets/threejsui/icon-tint.svg);
}

.Icon.threejs_tint_no_space > div {
  mask-image: url(./assets/threejsui/icon-tint-noSpace.svg);
}

.Icon.threejs_object_camera > div {
  mask-image: url(./assets/threejsui/icon-three-camera.svg);
}

.Icon.threejs_object_floor > div {
  mask-image: url(./assets/threejsui/icon-three-floor.svg);
}

.Icon.threejs_object_light > div {
  mask-image: url(./assets/threejsui/icon-three-light.svg);
}

.Icon.threejs_object_mesh > div {
  mask-image: url(./assets/threejsui/icon-three-mesh.svg);
}

.Icon.threejs_object_meshcollider > div {
  mask-image: url(./assets/threejsui/icon-three-meshcollider.svg);
}

.Icon.threejs_object_meshgroup > div {
  mask-image: url(./assets/threejsui/icon-three-meshgroup.svg);
}

.Icon.threejs_object_navmesh > div {
  mask-image: url(./assets/threejsui/icon-three-navmesh.svg);
}

.Icon.threejs_object_spot > div {
  mask-image: url(./assets/threejsui/icon-three-spot.svg);
}

.Icon.threejs_object_sprite > div {
  mask-image: url(./assets/threejsui/icon-three-sprite.svg);
}

.Icon.video_audio_off > div {
  background-image: url(./assets/video/audio_off.svg);
}

.Icon.video_audio_on > div {
  background-image: url(./assets/video/audio_on.svg);
}

.Icon.video_call_end > div {
  background-image: url(./assets/video/call_end.svg);
}

.Icon.video_call_start > div {
  background-image: url(./assets/video/call_start.svg);
}

.Icon.video_video_off > div {
  background-image: url(./assets/video/video_off.svg);
}

.Icon.video_video_on > div {
  background-image: url(./assets/video/video_on.svg);
}

.Icon.notifications_off > div {
  mask-image: url(./assets/notifications_off.svg);
}

.Icon.notifications_on > div {
  mask-image: url(./assets/notifications_on.svg);
}

.Icon.edite > div {
  mask-image: url(./assets/edite.svg);
}

.Icon.groups > div {
  mask-image: url(./assets/groups.svg);
}

.Icon.broadcast_listen > div {
  mask-image: url(./assets/icon-broadcast-listen.svg);
}

.Icon.broadcast_close > div {
  mask-image: url(./assets/icon-broadcast-close.svg);
}

.Icon.broadcast_maximize > div {
  mask-image: url(./assets/icon-broadcast-maximize.svg);
}

.Icon.broadcast_minimize > div {
  mask-image: url(./assets/icon-broadcast-minimize.svg);
}

.Icon.broadcast_sharing > div {
  mask-image: url(./assets/icon-broadcast-share.svg);
}

.Icon.question_mark > div {
  mask-image: url(./assets/question-mark.svg);
}

.Icon.tick > div {
  mask-image: url(./assets/tick.svg);
}

.Icon.red > div {
  background-color: var(--red);
}

.Icon.white > div {
  background-color: var(--gray-light);
}

.Icon.dark > div {
  background-color: var(--black-middle);
}

.Icon.black > div {
  background-color: var(--black);
}

.Icon.blue > div {
  background-color: var(--blue);
}

.Icon.main > div {
  background-color: var(--main);
}

.Icon.green > div {
  background-color: green;
}

.Icon.ia:hover > div {
  background-color: var(--gray-middle);
  transition: background-color 0.3s;
}
.Icon.ia.black:hover > div {
  background-color: var(--gray-middle);
}
.Icon.ia.dark:hover > div {
  background-color: var(--black);
}
.Icon.ia.white:hover > div {
  background-color: var(--gray-dark);
}

.Icon.ia.main:hover > div {
  background-color: var(--white)!important;
}

.Icon.ia.blue:hover > div {
  background-color: var(--white);
}

.Icon.ia.custom:hover > div {
  background-color: transparent;
}
